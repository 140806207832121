.sectionfivecnt {
    background-color: #fff;
    padding: 70px 0;
}

.sectionfivebody {
    max-width: 1080px;
    margin: 0px auto;
    padding: 70px;
    border-radius: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionfivebodycontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sectionfivebodycontentext {
    font-size: 36px;
    max-width: 400px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 30px;
}

.dplayss {
    background-color: #fff;
    white-space: nowrap;
}

.okassas {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
}

@media screen and (max-width: 1090px) {
    .sectionfivebody {
        margin: 0px 50px;
    }
    
}

@media screen and (max-width: 500px) {
    .sectionfivebody {
        margin: 0px 20px;
    }

    .sectionfivebody {
        max-width: 1080px;
        padding: 20px;
        border-radius: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sectionfivebodycontent {
        margin-bottom: 20px;
    }
    
    
}