.sectiontwobody {
  margin-top: 90px;
  background-color: #fff;
}

.sectiontwocnt {
  max-width: 1080px;
  margin: 0 auto;
  padding: 90px 0;
}

.sectiontwocnttop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.sectiontwotag {
  background-color: #0da5a520;
  padding: 6px 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.sectiontwotagtext {
  color: #0da5a5;
  font-weight: 700;
  font-size: 10px;
}

.sectiontwotitle {
  font-size: 32px;
  max-width: 350px;
  text-align: center;
  margin-bottom: 20px;
}

.sectiontwoparagraph {
  font-size: 12px;
  max-width: 350px;
  text-align: center;
  font-weight: 500;
  color: #666666;
  line-height: 18px;
}

.sectiontwocntbottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.sectiontwocntitems {
  border: 1px solid #e5eaee;
  padding: 30px;
  border-radius: 20px;
}

.sectiontwocnttitemstop {
  padding: 20px 0px 20px 0px;
}

.sectiontwocntitemstitle {
  font-size: 24px;
  margin-bottom: 15px;
}

.sectiontwocntitemsparagraph {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  line-height: 18px;
  margin-bottom: 15px;
}

.lernmorebtn {
  height: 40px;
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 20px;
  font-size: 12px;
  font-weight: 700;
  color: #0da5a5;
  border: 1px solid #e5eaee;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}

.sectiontwocntbottomtwo {
  border: 1px solid #e5eaee;
  padding: 30px;
  border-radius: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.sectwocntleft {
  padding: 30px;
}

.sectwocntright {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectwocntright img {
  width: 100%;
}

@media screen and (max-width: 1090px) {
  .sectiontwocnt {
    max-width: 1080px;
    margin: 0 50px;
    padding: 70px 0;
  }
}



@media screen and (max-width: 800px) {
  .sectiontwocntbottom {
    grid-template-columns: 1fr;
  }

  .sectiontwocntbottomtwo {
    grid-template-columns: 1fr;
  }

  .sectwocntright img {
    max-width: 300px;
  }
}
@media screen and (max-width: 500px) {
  .sectiontwocnt {
    max-width: 1080px;
    margin: 90px 20px;
    padding: 90px 0;
  }
}