.sectionthreecnt {
  margin: 0px auto;
  padding-top: 110px;
  max-width: 1080px;
}

.sectionthreebottomtwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.sectionthreebtmitems {
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.09) 0px 8px 24px;
  display: flex;
  padding: 20px;
  border-radius: 20px;
}

.sectionthreebtmitemsleft {
  padding: 10px;
}

.sectionthreebtmstars {
  display: flex;
  margin-bottom: 20px;
  gap: 5px;
}
.stbss {
  background-color: #0da5a520;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.sectionthreebtmcomment {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 40px;
}

.sectionthreebtmprof {
  display: flex;
  align-items: center;
  gap: 15px;
}

.sectionthreebtmprof img {
  height: 45px;
  width: 45px;
  object-fit: contain;
}

.sectthenam {
  font-size: 13px;
  font-weight: 800;
  margin-bottom: 8px;
  white-space: nowrap;
}

.sectstext {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  white-space: nowrap;
}

.sectionthreebtmitemsright {
  background-color: #f9fbfc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  border-radius: 20px;
}

.sectionthreebtmitemsright img {
  width: 100%;
}

@media screen and (max-width: 1090px) {
  .sectionthreecnt {
    margin: 0px 50px;
    margin-top: 90px;
    max-width: 1080px;
  }
}

@media screen and (max-width: 880px) {
  .sectionthreebtmitems {
    display: flex;
    flex-direction: column;
  }

  .sectionthreebtmitemsright {
    gap: 10px;
  }

  .sectionthreebtmitemsright img {
    width: 50px;
    height: 45px;
  }
}

@media screen and (max-width: 720px) {
  .sectionthreebottomtwo {
    display: flex;
    flex-direction: column;
  }

  .sectionthreebtmitems {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 500px) {
  .sectionthreecnt {
    margin: 80px 20px;
  }

  .sectionthreebtmitems {
    display: flex;
    flex-direction: column;
  }
}