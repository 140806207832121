.sectionone_cnt {
  padding: 110px 0;
}

.sectlsl {
  max-width: 1080px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  border-radius: 40px;
  padding: 60px;
}

.sectiononetop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  margin-bottom: 60px;
}

.sectiononetopimage {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.sectiononetop img {
  width: 300px;
}

.sectiononetopright {
  flex: 1;
  align-items: start;
  justify-content: center;
}

.scbody {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.sectiononetoptitle {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sectiononetopparagraph {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  line-height: 18px;
}

.sectiononebottomitems {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.sectiononeitems {
  border: 1px solid #e5eaee;
  padding: 20px;
  border-radius: 20px;
  display: grid;
}

.sectiononeitemsicons {
  background-color: #0da5a520;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.sectiononeitemstitle {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.sectiononeitemsparagraph {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #666666;
}

@media screen and (max-width: 1090px) {
  .sectionone_cnt {
    margin: 0 50px;
    padding: 110px 0;
  }
}

@media screen and (max-width: 880px) {
  .sectiononetop {
    flex-direction: column-reverse;
    align-items: center;
  }

  .sectiononebottomitems {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .sectionone_cnt {
    margin: 0 20px;
    padding: 110px 20px;
    border-radius: 20px;
  }
}
