.footer_body {
  background-color: #fff;
}

.footer_cnt {
  max-width: 1080px;
  margin: 0 auto;
}

.footercnttop {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.footercnttopitemslogo {
  font-weight: 800;
  margin-bottom: 20px;
}

.footersocialinks {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footercnttoplinkheader {
  font-size: 14px;
  font-weight: 800;
  color: #666;
  margin-bottom: 20px;
}

.footercntlinks {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 15px;
}

.footerextrahead {
  font-size: 12px;
  font-weight: 700;
  color: #666;
  margin-bottom: 10px;
}

.footerbottom {
  border-top: 1px solid #e5eaee;
  margin-top: 30px;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerbottomcopy {
  font-size: 12px;
  font-weight: 600;
  color: #666;
}

.footerbottomcopy span {
  font-weight: 800;
}

.footerbotomright {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footerbottomrightitems {
  font-size: 12px;
  font-weight: 600;
  color: #666666;
}

@media screen and (max-width: 1090px) {
  .footer_cnt {
    max-width: 1080px;
    margin: 0 50px;
  }
}

@media screen and (max-width: 800px) {
  .footercnttop {
    display: grid;
    grid-gap: 20px;
  }
}

@media screen and (max-width: 500px) {
  .footer_cnt {
    margin: 0 20px;
  }
}

@media screen and (max-width: 630px) {
  .footerbottom {
    display: grid;
    gap: 20px;
  }

  .footerbotomright {
    display: grid;
    gap: 20px;
  }
}
