.hero_cnt {
  max-width: 1080px;
  margin: 0px auto;
  padding-top: 200px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.heroleft {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.heroleftag {
  background-color: #0da5a520;
  padding: 6px 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.herolefttagtext {
  color: #0da5a5;
  font-weight: 700;
  font-size: 10px;
}

.heroleftitle {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 20px;
}

.heroleftparagraph {
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  font-weight: 500;
  max-width: 550px;
  margin-bottom: 20px;
}

.heroleftbuttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 35px;
  white-space: nowrap;
}

.heroleftbtnget {
  width: 200px;
  cursor: pointer;
}

.heroleftbtnplay {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #e5eaee;
  background-color: transparent;
}

.herolefttrusted {
  font-size: 12px;
  color: #666666;
  font-weight: 500;
  margin-bottom: 20px;
}

.learnbtn{
  height: 40px;
  padding: 0 20px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
}



.learnbtn {
  border: 1px solid #0da5a5;
  background-color: #0da5a5;
  color: #fff;
}


.herolefttrustimgs {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.herolefttrustimgs img {
  width: 100px;
}

.heroright {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroright img {
  width: 300px;
  object-fit: contain;
}

@media screen and (max-width: 1090px) {
  .hero_cnt {
    max-width: 1080px;
    margin: 0px 50px;
  }
}

@media screen and (max-width: 880px) {
  .hero_cnt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
 
}

@media screen and (max-width: 500px) {
    .hero_cnt {
      max-width: 1080px;
      margin: 0px 20px;
    }
  }
